import React, { useState, useEffect } from "react";
import AddJobModal from "../forms/employer/job";
import EmployeeHeader from "../common/header";
import CustomButton from "../common/button";
import { GetJobDetail } from "../../api/api";
import Loader from "../common/loader";
import { Link, useNavigate } from "react-router-dom";
import { PiPencilDuotone } from "react-icons/pi";
import AdminHeader from "../admin/header";
import AdminSidebar from "../admin/sidebar";
import JobTable from "../common/jobTable";
import { BsEnvelope } from "react-icons/bs";
import { RiMailSendLine } from "react-icons/ri";
import { BiPhoneCall } from "react-icons/bi";
import LimaArrowProfile from "../common/LimaArrowProfile";
import EmployeeFooter from "../common/footer";
import { getInitials } from "../common/GetInitials";
function JobDetailpageAdmim(props) {
  const user_type = localStorage.getItem("userType");
  let jid = localStorage.getItem("job_id");
  let skill = [];
  let navigate = useNavigate();
  /*Show modal and data state */
  const [lima, setLmia] = useState([]);
  const [lmiaStatusRejectComment, setLmiaStatusRejectComment] = useState([]);
  let [apiCall, setApiCall] = useState(false);
  let [isLoading, setIsLoading] = useState(true);
  const [showJobEditModal, setShowJobEditModal] = useState(false);
  const [TabActive, setTabActive] = useState("detail");
  const [jobData, setJobData] = useState("");
  const [pageNo, setpageNo] = useState(localStorage.getItem("PageNo") || 1);
  // const [employerKycData, setEmployrKycData] = useState("");

  /*Function to get employer data */
  const JobData = async () => {
    try {
      let resJobData = await GetJobDetail(jid);
      if (
        resJobData.data.data === null ||
        resJobData.data.data === undefined ||
        resJobData.data.data === "undefined" ||
        resJobData.data.data.length === 0
      ) {
        setJobData([]);
        setIsLoading(false);
      } else {
        setJobData(resJobData.data.data[0]);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  /*Render method to get employer data */
  useEffect(() => {
    JobData();
    if (apiCall === true) {
      setApiCall(false);
    }
    // eslint-disable-next-line
  }, [apiCall]);

  /*Set skill variable to array frm string */
  if (jobData !== "") {
    skill =
      jobData.keyskill === null ||
        jobData.keyskill === undefined ||
        jobData.keyskill === "undefined"
        ? []
        : jobData.keyskill.split(",");
  }
  return (
    <div className="">
      {(user_type === "admin" || user_type === "agent") && (
        <>
          <AdminHeader
            heading={
              <Link
                className="d-flex align-items-center "
                onClick={() => navigate(-1)}
              >
                <i className="icon icon-small-left bg-white circle-30 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                  <h3 className="font-size-6 mb-0 text-capitalize">
                    Job Details
                  </h3>
                </span>
              </Link>
            }
          />
          <AdminSidebar />
        </>
      )}

      {user_type === "admin" || user_type === "agent" ? null : <EmployeeHeader />}
      <div
        className={
          user_type === "admin" || user_type === "agent"
            ? "dashboard-main-container bg-light mt-12 mt-lg-12"
            : "bg-default-2 employer-detail-top-padding"
        }
      >
        <div className={`container${user_type === "admin" || user_type === "agent" ? "-fluid" : ""}`}>
          <div className="row text-left mt-5 pt-0">
            <div className="col-12 mb-1 d-none">
              <div className="bg-white shadow-9 d-flex">
                <div className="col-md-3 col-sm-6 media align-items-center company_box media border-right">
                  <div className="text_box text-left">
                    {jobData.logo ? <img
                      className="company_logo"
                      src={
                        jobData.logo === null
                          ? "image/logo-main-black.png"
                          : jobData.logo
                      }
                      alt=""
                    /> :
                      <p className="company_logo">AT</p>}
                  </div>
                  <div className="text_box text-left w-100 text-capitalize">
                    <h3 className="mb-0 font-size-6 heading-dark-color d-flex align-items-center text-break">
                      {jobData.job_title}
                      <CustomButton
                        className={
                          user_type === "user" || user_type === "agent" || jobData.length === 0
                            ? "d-none"
                            : "font-size-3 rounded-3 btn-primary border-0  absolute_top_right"
                        }
                        onClick={() => setShowJobEditModal(true)}
                      >
                        <PiPencilDuotone />
                      </CustomButton>
                    </h3>
                    <span>
                      {/* <b title="Operating Name">
                        {jobData.franchise
                          ? jobData.franchise
                          : "Unknown Client"}
                      </b> */}
                    </span>
                    {/* {jobData.address ? (
                      <div
                        className="font-size-3 text-default-color line-height-2 m-0 text-break mr-7"
                        title="Address"
                      >
                        <i className="fas fa-map-marker-alt mr-2"></i>
                        {jobData.address}
                        {", "} {jobData.city}
                        {" asdasa "}
                        {jobData.pin_code}
                        {", "}
                        {jobData.state}
                        {", "}
                        {jobData.country}
                      </div>
                    ) : null} */}
                    {/* <p className="font-size-3 text-default-color line-height-2 m-0">
                      {jobData.department}
                    </p> */}
                  </div>
                </div>
                {jobData.email ? (
                  <div className="col-md-3 col-sm-6 px-5 pt-5 pb-5 border-right">
                    <div className="d-flex justify-content-between align-items-center">
                      <Link
                        className="text-dark font-size-5 w-100 text-break"
                        to={`mailto:${jobData.email}`}
                      >
                        <BsEnvelope className="text-primary font-size-5 " />
                        {jobData.email}
                      </Link>
                      {user_type === "admin" || props.self === "no" ? (
                        <CustomButton
                          title={"Send Custom Email"}
                          className="font-size-4 rounded-3 btn-primary py-0 d-none"
                        /*Functionalities have to be done. */
                        >
                          {/*Take off "d-none" when you Send Custom Email API or when you're told to remove it*/}
                          <RiMailSendLine />
                        </CustomButton>
                      ) : null}
                    </div>
                    {jobData.contact_no_other && (
                      <Link
                        className="text-dark font-size-5 w-100"
                        to={`tel:${jobData.contact_no_other}`}
                      >
                        <BiPhoneCall className="text-primary font-size-5" />
                        {jobData.contact_no_other}
                      </Link>
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div className="col px-5 pt-5 pb-5  border-right position-relative">
                  <div className="personal_info_box d-flex align-items-center justify-content-left flex-wrap">
                    {!jobData.location &&
                      !jobData.language &&
                      !jobData.address ? (
                      <div className="info_box text-left text-capitalize">
                        <span className="font-size-3 text-smoke  mr-7">
                          No Data FOund
                        </span>
                      </div>
                    ) : (
                      <>
                        <div className="info_box text-left text-capitalize text-break">
                          {jobData.your_duties ? (
                            <span
                              className="font-size-3 text-smoke  mr-7"
                              title="Current Location"
                            >
                              <img
                                className="mr-1"
                                height={"16px"}
                                src="image/icons/marker.svg"
                                alt="Location"
                              />
                              {jobData.your_duties} , {jobData.industry_type}{" "}
                              {jobData.location}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="info_box text-left text-capitalize text-break">
                          {jobData.language ? (
                            <span
                              className="font-size-3 text-smoke  mr-7"
                              title="User Language"
                            >
                              <img
                                className="mr-1"
                                height={"16px"}
                                src="image/icons/language.svg"
                                alt="language"
                              />
                              {jobData.language}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="info_box text-left text-capitalize text-break">
                          {jobData.address ? (
                            <span
                              className="font-size-3 text-smoke  mr-7"
                              title="Currently Located Country"
                            >
                              <img
                                className="mr-1"
                                height={"16px"}
                                src="image/icons/address-book.svg"
                                alt="Address"
                              />
                              {jobData.address}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* LMIA */}
            <div className="col-12">
              {lima && user_type !== "user" ? (
                <LimaArrowProfile
                  lmia={lima}
                  lmiaStatusRejectComment={lmiaStatusRejectComment}
                />
              ) : null}
            </div>
            <div className="col-12 order-2 order-xl-1">
              <div className="bg-white">
                {/*----Profile Header----*/}
                <ul
                  className="nav border-top border-bottom border-mercury user_profile_tab"
                  id="myTab"
                  role="tablist"
                >
                  <li className="tab-menu-items nav-item">
                    <Link
                      className={
                        TabActive === "detail"
                          ? "text-uppercase font-size-3 font-weight-bold text-default-color py-4 mb-0 px-10 active"
                          : "text-uppercase font-size-3 font-weight-bold text-default-color py-4 mb-0 px-10"
                      }
                      id="home-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                      onClick={() => setTabActive("detail")}
                    >
                      Details
                    </Link>
                  </li>
                  <li
                    className={
                      user_type === "user"
                        ? "d-none"
                        : "tab-menu-items nav-item"
                    }
                  >
                    <Link
                      className={
                        TabActive === "jobs"
                          ? "text-uppercase font-size-3 font-weight-bold text-default-color py-4 mb-0 px-10 active"
                          : "text-uppercase font-size-3 font-weight-bold text-default-color py-4 mb-0 px-10"
                      }
                      id="appliedJobs"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="appliedJobs"
                      aria-selected="true"
                      onClick={() => setTabActive("jobs")}
                    >
                      Job Responses
                    </Link>
                  </li>
                </ul>
                {/*---Profile Details----*/}
                <div
                  className={TabActive === "detail" ? "tab-content" : "d-none"}
                  id="myTabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    {/*----About Job----*/}
                    {isLoading ? (
                      <div className="table-responsive main_table_div">
                        <Loader />
                      </div>
                    ) : (
                      <div
                        className={
                          user_type === "admin"
                            ? "row m-0 p-8 overflow-hidden"
                            : "row m-0 p-8 overflow-hidden"
                        }
                      >
                        {/* <!-- Company Profile --> */}

                        <div className="text-capitalize company_detail_box w-100 m-0 bg-light rounded p-8 position-relative">
                          <CustomButton
                            className={
                              user_type === "user" || user_type === "agent" || jobData.length === 0
                                ? "d-none"
                                : "font-size-3 rounded-3 btn-primary border-0  absolute_top_right"
                            }
                            onClick={() => setShowJobEditModal(true)}
                          >
                            <PiPencilDuotone />
                          </CustomButton>
                          <div className="col-md-12 col-sm-12 media align-items-center company_box media">
                            <div className="text_box text-left">
                              {jobData.logo ? <img
                                className="company_logo"
                                src={
                                  jobData.logo === null
                                    ? "https://macsnh.org/wp-content/uploads/2019/08/demo-logo-black.png"
                                    : jobData.logo
                                }
                                alt=""
                              /> : <p className="company_logo"
                                style={{ fontSize: "50px" }}>{getInitials(jobData.company_name)}</p>}
                            </div>
                            <div className="text_box text-left w-100 text-capitalize">
                              <h3 className="mb-0 font-size-6 heading-dark-color d-flex align-items-center text-break">
                                {jobData.job_title}{" "}
                                {/* {jobData.employement
                                  ? `(${jobData.employement})`
                                  : ""} */}
                              </h3>
                              <h5 className="mb-0 font-size-6 heading-dark-color d-flex align-items-center text-break">
                                {/* <b> {jobData.franchise}{" "}</b> */}
                                { /*<small>
                                  {jobData.company_name
                                    ? `(${jobData.company_name})`
                                    : ""}</small>*/}
                              </h5>

                              <hr className={`my-3 ${!jobData.location &&
                                !jobData.language &&
                                !jobData.address &&
                                !jobData.your_duties ? "d-none" : ""}`} />
                              <div className="personal_info_box d-flex align-items-center justify-content-left flex-wrap w-100">
                                {!jobData.location &&
                                  !jobData.language &&
                                  !jobData.address &&
                                  !jobData.your_duties ? (
                                  ""
                                ) : (
                                  <>

                                    <div className="info_box text-left text-capitalize">
                                      {jobData.your_duties ? (
                                        <div
                                          className="font-size-3 text-smoke mr-7"
                                          title="Current Location"
                                        >
                                          <img
                                            className="mr-1"
                                            height={"16px"}
                                            src="image/icons/marker.svg"
                                            alt="Location"
                                          />
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: jobData.your_duties,
                                            }}
                                          />{" "}
                                          {jobData.industry_type &&
                                            `${jobData.industry_type} ,`}
                                          {jobData.location && ` ${jobData.location}`}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="info_box text-left text-capitalize">
                                      {jobData.language ? (
                                        <div
                                          className="font-size-3 text-smoke  mr-7"
                                          title="User Language"
                                        >
                                          <img
                                            className="mr-1"
                                            height={"16px"}
                                            src="image/icons/language.svg"
                                            alt="language"
                                          />
                                          {jobData.language}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {/* <div className="info_box text-left text-capitalize text-break">
                                      {jobData.address ? (
                                        <span
                                          className="font-size-3 text-smoke  mr-7"
                                          title="Currently Address"
                                        >
                                          <img
                                            className="mr-1"
                                            height={"16px"}
                                            src="image/icons/address-book.svg"
                                            alt="Address"
                                          />
                                          {jobData.address}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div> */}
                                  </>
                                )}
                              </div>
                              <hr className="my-3" />
                              <div className="">
                                <div className="d-none">
                                  <h4 className="text-black-2 mb-5 font-size-5 d-flex align-items-center justify-content-space-between">
                                    <span>{/*Kyc Details*/}</span>
                                    <CustomButton
                                      className="font-size-3 rounded-3 btn-primary border-0  absolute_top_right"
                                      onClick={() => setShowJobEditModal(true)}
                                    >
                                      <PiPencilDuotone />
                                    </CustomButton>
                                  </h4>
                                </div>
                                {!jobData.industry &&
                                  !jobData.corporation &&
                                  !jobData.company_size &&
                                  !jobData.job_type &&
                                  !jobData.education &&
                                  !jobData.role_category &&
                                  !jobData.experience_required &&
                                  !jobData.department ? (
                                  <div className="text-left row m-0">
                                    <div className="font-size-3 mb-4 mr-10">
                                      No Data Found
                                    </div>
                                  </div>
                                ) : (
                                  <div className="text-left row m-0">
                                    {/* {jobData.industry ? (
                                  <div
                                    className="font-size-3 mb-4 mr-10"
                                    title="Industry"
                                  >
                                    <i className="far fa-building mr-2"></i>
                                    {jobData.industry}
                                  </div>
                                ) : null} */}

                                    {jobData.corporation ? (
                                      <div
                                        className="font-size-3 mb-4 mr-10"
                                        title="Business Type"
                                      >
                                        <i className="fas fa-briefcase mr-2"></i>
                                        {jobData.corporation}
                                      </div>
                                    ) : null}
                                    {jobData.company_size ? (
                                      <div
                                        className="font-size-3 mb-4 mr-10"
                                        title="Client's team size"
                                      >
                                        <i className="fas fa-user-friends mr-2"></i>
                                        {jobData.company_size}
                                      </div>
                                    ) : null}
                                    {jobData.job_type ? (
                                      <div
                                        className="info_box text-left font-size-3 mb-4 mr-10"
                                        title="Job Type"
                                      >
                                        <span className="font-size-3 text-smoke text-capitalize">
                                          <b>Job Type </b> {jobData.job_type}
                                        </span>
                                      </div>
                                    ) : null}
                                    {jobData.education ? (
                                      <div
                                        className="font-size-3 mb-4 mr-10"
                                        title="Edication"
                                      >
                                        <i className="fas fa-graduation-cap mr-2"></i>
                                        <Link className="text-dark" to={""}>
                                          {jobData.education}
                                        </Link>
                                      </div>
                                    ) : null}
                                    {jobData.role_category ? (
                                      <div
                                        className="font-size-3 mb-4 mr-10"
                                        title="Hiring for"
                                      >
                                        <i className="fas fa-bullhorn mr-2"></i>
                                        {jobData.role_category}
                                      </div>
                                    ) : null}
                                    <div className="info_box text-left font-size-3 mb-4 mr-10 text-capitalize">
                                      {jobData.experience_required ? (
                                        <span
                                          className="font-size-3 text-smoke"
                                          title="Total Experience"
                                        >
                                          <img
                                            className="mr-1"
                                            height={"16px"}
                                            src="image/icons/envelope.svg"
                                            alt="Email"
                                          />
                                          {jobData.experience_required} Years
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {jobData.salary ? (
                                      <div className="info_box text-left font-size-3 mb-4 mr-10">
                                        <span
                                          className="font-size-3 text-smoke  text-capitalize"
                                          title="Salary"
                                        >
                                          <b> Salary: </b>$ {jobData.salary}
                                        </span>
                                      </div>
                                    ) : null}
                                    {jobData.department ? (
                                      <div className="info_box text-left font-size-3 mb-4 mr-10">
                                        <span
                                          className="font-size-3 text-smoke text-capitalize"
                                          title="NOC code"
                                        >
                                          <b> NOC Code: </b>
                                          {jobData.department}
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                )}
                              </div>
                              {jobData.email ? (
                                <div className="p-0 d-none">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <Link
                                      className="font-size-3 text-break btn btn-outline-secondary btn-rounded px-4"
                                      to={`mailto:${jobData.email}`}
                                    >
                                      <BsEnvelope className="text-secondary font-size-5 mr-2" />
                                      {jobData.email}
                                    </Link>
                                    {user_type === "admin" ||
                                      props.self === "no" ? (
                                      <CustomButton
                                        title={"Send Custom Email"}
                                        className="font-size-4 rounded-3 btn-primary py-0 d-none"
                                      /*Functionalities have to be done. */
                                      >
                                        {/*Take off "d-none" when you Send Custom Email API or when you're told to remove it*/}
                                        <RiMailSendLine />
                                      </CustomButton>
                                    ) : null}
                                  </div>
                                  {jobData.contact_no_other && (
                                    <Link
                                      className="text-dark font-size-5 w-100"
                                      to={`tel:${jobData.contact_no_other}`}
                                    >
                                      <BiPhoneCall className="text-primary font-size-5" />
                                      {jobData.contact_no_other}
                                    </Link>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              {/* <p className="font-size-3 text-default-color line-height-2 m-0 text-break">
                                    {jobData.department}
                                  </p> */}
                            </div>
                          </div>
                        </div>
                        <div className="text-capitalize company_detail_box w-100 m-0 mt-5 bg-light rounded p-8 position-relative">
                          <div className="col-md-12 col-lg-12 p-0">
                            <div>
                              <h4 className="text-black-2 mb-0 font-size-5 d-flex text-break align-items-center justify-content-space-between">
                                Job Description
                                {/* <CustomButton
                                  className="font-size-3 rounded-3 btn-primary border-0  absolute_top_right"
                                  onClick={() => setShowJobEditModal(true)}
                                >
                                  <PiPencilDuotone />
                                </CustomButton> */}
                              </h4>
                              <div className="pt-5 text-left">
                                {jobData.job_description ? (
                                  // <p className="font-size-4 mb-8 ">
                                  //   {jobData.job_description}
                                  // </p>
                                  <div className="font-size-4 mb-8">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: jobData.job_description,
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <p className="font-size-4 mb-8 text-center">
                                    No data Found
                                  </p>
                                )}
                              </div>
                              <div className="pt-5 light-mode-texts border-top border-width-1 border-default-color">
                                <div className="tags">
                                  <h4 className="text-black-2 mb-0 font-size-5 d-flex text-break align-items-center justify-content-space-between">
                                    Skills
                                  </h4>
                                  {skill.length > 0 ? (
                                    <ul className="list-unstyled d-flex align-items-center flex-wrap row px-2">
                                      {(skill || []).map((skill, i) =>
                                        skill === "" ? null : (
                                          <li key={i}>
                                            <span className="text-capitalize bg-regent-opacity-15 min-width-px-96 mr-3 text-center rounded-3 px-6 py-1 font-size-3 text-black-2 mt-2 m-1 text-break">
                                              {skill}
                                            </span>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    <div className="text-center">
                                      <b>No Skill's found</b>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* <!-- Sidebar End --> */}
                <div
                  className={
                    TabActive === "jobs"
                      ? "row m-0  justify-content-center"
                      : "d-none"
                  }
                  id="appliedJobs"
                  role="tabpanel"
                  aria-labelledby="appliedJobs"
                >
                  <div className="response_main_div w-100">
                    <JobTable
                      job_id={jid}
                      heading={"jobdetail"}
                      response={"response"}
                      detail={"job_detail"}
                      setLmia={setLmia}
                      apiCall={apiCall}
                      setApiCall={setApiCall}
                      setLmiaStatusRejectComment={setLmiaStatusRejectComment}
                      pageNo={pageNo}
                      setpageNo={setpageNo}
                    />
                  </div>
                  {/* <!-- Top Start --> */}
                </div>
                <div
                  className={
                    TabActive === "notes" ? "justify-content-center " : "d-none"
                  }
                >
                  {/* {TabActive === "notes" ? (
                    <AddCompanyfollowup
                      company_id={cid}
                      setApiCall={setApiCall}
                    />
                  ) 
                   : //  <div className="p-10 notes_container">
                  //         <div className="single_note mb-5">
                  //           <small>Created on: 2023-08-03 17:10:53</small>
                  //           <div className="card p-5">
                  //             This is some text within a card body.
                  //           </div>
                  //         </div>
                  //         <div className="single_note mb-5">
                  //           <small>Created on: 2023-08-03 17:10:53</small>
                  //           <div className="card p-5">
                  //             This is some text within a card body.
                  //           </div>
                  //         </div>
                  //         <div className="single_note mb-5">
                  //           <small>Created on: 2023-08-03 17:10:53</small>
                  //           <div className="card p-5">
                  //             This is some text within a card body.
                  //           </div>
                  //         </div>
                  //       </div>
                  null*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {user_type === "admin" || user_type === "agent" ? "" : <EmployeeFooter />}
      {showJobEditModal ? (
        <AddJobModal
          show={showJobEditModal}
          jobdata={jid}
          admin={"admin"}
          setApiCall={setApiCall}
          apiCall={apiCall}
          close={() => setShowJobEditModal(false)}
        />
      ) : null}
      {/* {showCompanyInfoModal ? (
        <CompanyDetails
          employerId={user_type === "company" ? company_id : cid}
          show={showCompanyInfoModal}
          apiCall={apiCall}
          setApiCall={setApiCall}
          close={() => setShowCompanyInfoModal(false)}
        />
      ) : null}
      {showKycComplainDetailsModal ? (
        <KycComplianceDetails
          employerId={user_type === "company" ? company_id : cid}
          show={showKycComplainDetailsModal}
          apiCall={apiCall}
          setApiCall={setApiCall}
          close={() => setShowKycComplainDetailsModal(false)}
        />
      ) : null}
      {showDoc ? (
        <EmployerDocumentModal
          show={showDoc}
          close={() => setShowDoc(false)}
          employer_id={user_type === "company" ? company_id : cid}
        />
      ) : null} */}
    </div>
  );
}

export default JobDetailpageAdmim;
