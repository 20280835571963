import React, { useState, useEffect } from "react";
import { AddAllUserFollowup/*AddFollowup*/, getAllUsersFollowUpData,/* getSingleFollowup */ } from "../../api/api";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { toast } from "react-toastify";
import Pagination from "./pagination";
import { Link } from "react-router-dom";
import Loader from "./loader";
import ConvertTime from "./Common function/ConvertTime";
export default function EmployeeNotesTable({ search, userType }) {
  let [isLoading, setIsLoading] = useState(true);
  let [apiCall, setApiCall] = useState(false);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState("");
  const [currentPage, setCurrentPage] = useState(localStorage.getItem("PageNo") || 1);
  const [recordsPerPage] = useState(10);
  /* Shorting states */
  const [columnName, setcolumnName] = useState("id");
  const [sortOrder, setSortOrder] = useState("DESC");
  /*Function to get Employee's notes */
  const EmployeeNotes = async () => {
    try {
      // let res = await getSingleFollowup(
      //   "",
      //   columnName,
      //   sortOrder,
      // currentPage,
      // recordsPerPage,
      //   "1",
      //   search
      // );
      const res = await getAllUsersFollowUpData("", userType, columnName, sortOrder, search, currentPage,
        recordsPerPage, 1)

      if (res.status === 1) {
        setTotalData(res.data.total_rows);
        setData(res.data.data.filter((item) => item.status === "1"));
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    EmployeeNotes();
    if (apiCall === true) {
      setApiCall(false);
    }
    // eslint-disable-next-line
  }, [apiCall, sortOrder, search, currentPage]);

  /*Pagination Calculation */
  const nPages = Math.ceil(totalData / recordsPerPage);

  /*Sorting Function */
  const handleSort = (columnName) => {
    setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    setcolumnName(columnName);
    setCurrentPage(1);
  };
  /*function to change Notes status */
  const OnStatusChange = async (e, value) => {
    // e.preventDefault()
    let data = {
      id: value.id,
      status: e,

    };
    try {
      // let responseData = await AddAllUserFollowup/*AddFollowup*/(data);
      let responseData = await AddAllUserFollowup(data);
      if (responseData.message === "follow up updated successfully") {
        toast.success("Followup Updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setApiCall(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <div className="bg-white shadow-8 datatable_div  pt-7 rounded pb-9 px-5 ">
        <div className="table-responsive main_table_div">
          {isLoading ? (
            <Loader />
          ) : (
            <table className="table table-striped main_data_table text-capitalize ">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="border-0 font-size-4 font-weight-normal"
                  >

                    <Link
                      to={""}
                      onClick={() => {
                        handleSort("user_id");
                      }}
                      className="text-gray"
                      title="Sort by User Id"
                    >
                      {userType === "employee" ? "EID" : "CID"}
                    </Link>
                  </th>
                  <th
                    scope="col"
                    className="border-0 font-size-4 font-weight-normal"
                  >
                    <Link
                      to={""}
                      onClick={() => {
                        handleSort(userType === "employee" ? "name" : "company_name");
                      }}
                      className="text-gray"
                      title="Sort by name"
                    >
                      Name
                    </Link>
                  </th>
                  <th
                    scope="col"
                    className="border-0 font-size-4 font-weight-normal"
                  >
                    <Link
                      to={""}
                      onClick={() => {
                        handleSort("created_at");
                      }}
                      className="text-gray"
                      title="Sort by added date"
                    >
                      Date added
                    </Link>
                  </th>
                  <th
                    scope="col"
                    className="border-0 font-size-4 font-weight-normal"
                  >
                    <Link
                      to={""}
                      onClick={() => {
                        handleSort("subject");
                      }}
                      className="text-gray"
                      title="Sort by Subject"
                    >
                      Subject
                    </Link>
                  </th>
                  {/* <th
                    scope="col"
                    className=" border-0 font-size-4 font-weight-normal"
                  >
                    <Link
                      to={""}
                      onClick={() => {
                        handleSort("remark");
                      }}
                      className="text-gray"
                      title="Sort by Description"
                    >
                      Description
                    </Link>
                  </th> */}
                  <th
                    scope="col"
                    className="border-0 font-size-4 font-weight-normal"
                    title="status"
                  >
                    status
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Map function to show the data in the list*/}
                {totalData === 0 || data.length === 0 ? (
                  <tr>
                    <th colSpan={6} className="bg-white text-center">
                      No Data Found
                    </th>
                  </tr>
                ) : (
                  (data || []).map((data, i) => (
                    <React.Fragment key={i}>
                      <tr className="text-capitalize" >
                        <th className=" py-5">
                          <p className="font-size-3 font-weight-normal text-black-2 mb-0"
                            title={data.user_id}>
                            {data.user_id}
                          </p>
                        </th>
                        <th className=" py-5">
                          <Link
                            to={userType === "employee" ? `/${data.user_id}` : `/client_detail`}
                            // onClick={
                            //   empdata.name !== null
                            //     ? () => employeeDetails(empdata.employee_id)
                            //     : null
                            // }
                            onClick={userType === "employee"
                              ? null
                              : () =>
                                localStorage.setItem(
                                  "company_id",
                                  data.user_id
                                )
                            }
                            title="User Details"
                          >
                            <div className="d-flex profile_box gx-2">
                              <div className="media  align-items-center">
                                <div className="circle-30 mx-auto overflow-hidden">
                                  {data.profile_photo === null ||
                                    data.profile_photo === "" ||
                                    data.profile_photo === undefined ||
                                    data.profile_photo === "null" ||
                                    data.profile_photo ===
                                    "undefined" ? (
                                    <img
                                      src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                                      alt=""
                                      className="w-100"
                                    />
                                  ) : (
                                    <img
                                      src={data.profile_photo}
                                      alt=""
                                      className="w-100"
                                    />
                                  )}
                                </div>
                              </div>

                              <div className=" mb-0">
                                {data.name === null ||
                                  data.name === undefined ||
                                  data.name === "undefined" ||
                                  data.name === "" ? (
                                  <p className="font-size-3  mb-0">N/A</p>
                                ) : (
                                  <p
                                    className="m-0 text-black-2 font-weight-bold text-capitalize text-truncate"
                                    title={data.name}
                                  >
                                    {data.name}
                                  </p>
                                )}
                                {/* {empdata.gender || empdata.marital_status ? (
                                <p className="text-gray font-size-2 m-0 text-capitalize">
                                  {empdata.gender === "female"
                                    ? "F"
                                    : empdata.gender === "male"
                                      ? "M"
                                      : "O"}
                                  {/*Calculation of age from date of birth*/}
                                {/* (
                                  {empdata.marital_status ||
                                    empdata.date_of_birth
                                    ? `${empdata.marital_status
                                    },${moment().diff(
                                      empdata.date_of_birth,
                                      "years"
                                    )} Y`
                                    : null}
                                  )
                                </p>
                              ) : null} */}
                                {/* {empdata.is_featured === "1" ||
                                empdata.is_featured === 1 ? (
                                <span className="bg-orange text-white featured_tag">
                                  Featured
                                </span>
                              ) : null}
                              {empdata.created_by_admin === "0" ||
                                empdata.created_by_admin === 0 ? (
                                <span className="bg-info text-white web_tag">
                                  Web
                                </span>
                              ) : null} */}
                              </div>
                            </div>
                          </Link>
                        </th>
                        <th className="py-5">
                          <div className="font-size-3 mb-0 font-weight-semibold text-black-2"
                            title={ConvertTime({ _date: data.created_at, format: "DD MMMM, YYYY" })}>
                            <ConvertTime _date={data.created_at} format={"DD MMMM, YYYY"} />
                            {/* {moment(data.created_at).format("DD MMMM, YYYY")} */}
                          </div>
                        </th>
                        <th className="py-5 ">
                          <div
                            className="font-size-3 mb-0 font-weight-semibold text-black-2 text-truncate"
                            title={data.subject}
                          >
                            {data.subject}
                          </div>
                        </th>
                        {/* <th className=" py-5">
                        <h3 className="font-size-3 font-weight-normal text-black-2 mb-0  text-truncate">
                          {data.remark}
                        </h3>
                      </th> */}
                        <th className="py-5 min-width-px-100 ">
                          <div
                            className="btn-group button_group d-flex"
                            role="group"
                          >
                            <DropdownButton
                              as={ButtonGroup}
                              title={
                                data.status === "1" ? "Private" : "Complete"
                              }
                              size="sm"
                              className="user_status_btn btn-primary text-white ml-1"
                              onSelect={(e) => OnStatusChange(e, data)}
                            >
                              <Dropdown.Item
                                value={"2"}
                                eventKey={2}
                                className="text-capitalize"
                              >
                                Complete
                              </Dropdown.Item>
                            </DropdownButton>
                          </div>
                        </th>
                      </tr>
                      <tr>

                        <td
                          colSpan={5}
                          className="font-size-3 font-weight-normal text-black-2 mb-0text-truncate text-break"
                        >
                          <div className=" d-flex">
                            <b className="" title="Description">Description: { }</b>
                            <span
                              className="px-2"
                              dangerouslySetInnerHTML={{
                                __html: data.remark,
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {/* <!-- Pagination- --> */}
      <div className="pt-2">
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          total={totalData}
          count={data.length}
        />
      </div>
    </div>
  );
}
